<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">县域产业平台搭建计划</div>
      <div class="sub-title">实施“数商兴农”，助力县域经济</div>
      <div class="large-paragraph-describe">
        通过加速数字经济产业集聚，加快乡村振兴培育工作，突出区域特色，完成县域网点数字化改造，促进农村电商产业转型升级，强化数字经济与三二一产实体经济的深度融合，促进产业转型，推动优势供应链下行，优质农产品上行，助力区域经济发展。
      </div>
    </div>
    <img
      class="large-image-h664"
      v-for="src in srcModules"
      :src="src"
      :key="src"
    />
    <action-sheet module="新农村县域产业平台">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    srcModules: [
      require("@/image/region/img_network_county_village@3x.png"),
      require("@/image/region/img_cultivate_county_village@3x.png"),
      require("@/image/region/img_isc_county_village@3x.png"),
      require("@/image/region/img_level_county_village@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-paragraph-describe {
  margin-bottom: 20px !important;
}
</style>
